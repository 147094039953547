import React from 'react'
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import LayoutTwo from '../components/layoutTwo';
import SEO from "../components/seo"

const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const AreaguideLandingPage = loadable(() => import("../components/AreaGuideLanding/AreaguideLandingPage"));

const AreaguideLanding = ({ data }, props) => {
	const PageData = data?.strapiPage
	const areaGuideData = data?.allStrapiAreaGuide?.edges

	return (
		<LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
			<div className="layout-padding-top"></div>

			<BreadcrumbModule
				subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title}
				subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug}
				parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title}
				parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug}
				title={PageData.title}
			/>

			{PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
				return (
					<>
						{module.strapi_component === "page-modules.plain-content" && <StaticIntro {...module} pagename={PageData.title} />}
					</>
				)
			})}

			<AreaguideLandingPage areaGuideData={areaGuideData} />


		</LayoutTwo>
	)
}

export const Head = ({ data }) => {
	const PageData = data?.strapiPage
	return (
		<SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
	)
}

export default AreaguideLanding


export const query = graphql`
query ($page_id: String) {
	strapiPage(id: {eq: $page_id}) {
        ...PageFragment
		Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
	}
	allStrapiAreaGuide(filter: {publish: {eq: true}}) {
		edges {
			node {
				...AreaGuideFragment
				areas {
				  title
				  slug
				}
				add_content {
					... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
						...PlainContentFragment
					}
					... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE {
						...ImageFragment
					}
				}
			}
		}
	}
  }
`